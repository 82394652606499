import * as React from "react"
import {Link} from "gatsby"
import $ from 'jquery'

import Layout from "../components/layout"
import Seo from "../components/seo"
import {Component, useState} from "react";

import * as noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';
import {ChevronRight, ExpandMore, MaterialHelp} from "../components/material-icons";


class PlanBuilder extends Component {


    constructor(props) {
        super(props);
        this.state = {
            faq1: false,
            faq2: false,
            plantype: 1,
            rpy: 500
        }
    }

    componentDidMount() {
        var valuesSlider = document.getElementById('slider');
        var valuesForSlider = [50, 100, 250, 500, 800, 1000, 1500, 2000, 3000, "Unlimited"]; // 16 values

        var format = {
            to: function (value) {
                return valuesForSlider[Math.round(value)];
            },
            from: function (value) {
                return valuesForSlider.indexOf(Number(value));
            }
        };

        noUiSlider.create(valuesSlider, {
            start: [1000],
            range: {min: 0, max: valuesForSlider.length - 1},
            step: 1,
            connect: 'lower',
            tooltips: true,
            format: format,
            pips: {mode: 'count', density: 5, format: format, values: valuesForSlider.length},
        });

        var pips = valuesSlider.querySelectorAll('.noUi-value');

        function clickOnPip() {
            var value = Number(this.getAttribute('data-value'));
            console.log(value);
            valuesSlider.noUiSlider.set(valuesForSlider[Math.round(value)]);
        }

        for (var i = 0; i < pips.length; i++) {
            pips[i].style.cursor = 'pointer';
            pips[i].addEventListener('click', clickOnPip);
        }
        const self = this;

        function doSomething(values, handle, unencoded, tap, positions, noUiSlider) {
            $('#overages').show();
            document.getElementById("rpysy").innerText = values + " Recipients";
            document.getElementById("rpysm").innerText = values + " Recipients";
            var value = values[0]
            var cost = 70;
            var cpr = 0.75;
            var discount = 0;
            if (value === 50) {
                cpr = 0.75;
            } else if (value === 100) {
                cpr = 0.72;
            } else if (value === 250) {
                cpr = 0.69;
            } else if (value === 500) {
                cpr = 0.66;
            } else if (value === 800) {
                cpr = 0.63;
            } else if (value === 1000) {
                cpr = 0.6;
            } else if (value === 1500) {
                cpr = 0.57;
            } else if (value === 2000) {
                cpr = 0.54;
            } else if (value === 3000) {
                cpr = 0.51;
            } else if (value === "Unlimited") {
                cost = 2800;
                $('#overages').hide();
            }
            if (value !== "Unlimited") {
                cost = Math.round(value * cpr);
            }
            discount = Math.round((cost / 100) * 20);

            console.log(self.state.plantype);

            $('.rpysy-value').html(cost + ' USD');
            $('.rpysm-value').html(Math.round(cost / 12) + ' USD');
            $('#payabley').html(cost - discount + ' USD');
            $('#payablem').html(Math.round(cost / 12) + ' USD');
            $('#discount-value').html("-" + discount + ' USD');
            $('#overages-value').html(cpr + ' USD');
        }

        valuesSlider.noUiSlider.on('slide.one', doSomething);
        valuesSlider.noUiSlider.on('set.one', doSomething);
    }

    render() {
        return (
            <Layout>
                <div className="builder">
                    <h2>Build a Custom Growth Plan</h2>

                    <div className="builder-section">
                        <div className="builder-section__scale">
                            <div className="builder-steps">
                                <div className="builder-step builder-step--completed">
                                    <span className="step-number">1</span>
                                    <p className="step-name">Recipient per year</p>
                                    <ChevronRight color={"#485BFF"} size={"22px"}/>
                                </div>
                                <div className="builder-step">
                                    <span className="step-number">2</span>
                                    <p className="step-name">Payment Info</p>
                                    <ChevronRight color={"#A7A7A7"} size={"22px"}/>
                                </div>
                                <div className="builder-step">
                                    <span className="step-number">3</span>
                                    <p className="step-name">Review</p>
                                </div>
                            </div>
                            <p className="head">How many recipients you issue per year (RPY)?</p>
                            <p>Recipients per year is calculated by number of recipients who gets the
                                credentials from your organization in a year. Also called as RPYs.</p>
                            <div id="slider"></div>
                            <div className="horizontal pointer">
                                <MaterialHelp color={"#485BFF"} size={"26px"}/>
                                <p onClick={() => this.setState({faq1: !this.state.faq1})}>How many recipient per year
                                    should I select ?</p>
                                <ExpandMore color={"#A7A7A7"} size={"24px"}/>
                            </div>
                            <p className={this.state.faq1 ? "sub mgb10" : "hide"}>To get an idea of your Recipients Per
                                Year (RPY) count, The count
                                of RPYs is based on the number of recipients who gets the certificate from your organization. Recipients are only counted once per
                                year, even if they get multiple certificates or badges throughout the year.
                            </p>
                            <div className="horizontal pointer">
                                <MaterialHelp color={"#485BFF"} size={"26px"}/>
                                <p onClick={() => this.setState({faq2: !this.state.faq2})}>What is the rate if I go over
                                    my estimate ?</p>
                                <ExpandMore color={"#A7A7A7"} size={"24px"}/>
                            </div>
                            <p className={this.state.faq2 ? "sub mgb10" : "hide"}>
                                We don't stop issuing your credentials. Any additional recipient over your selected plan
                                amount will be charged at the overage price mentioned above and billed the following month. Learn
                                more about additional data. Additionally, volume discounts are baked in. You can save
                                money by giving your organization room to grow!
                            </p>
                        </div>
                        <div className="builder-section__output">
                            <div className="switch">
                                <p className={this.state.plantype === 0 ? "switch-selected" : null}
                                   onClick={() => this.setState({plantype: 0})}>Monthly</p>
                                <p className={this.state.plantype === 1 ? "switch-selected" : null}
                                   onClick={() => this.setState({plantype: 1})}>Annually</p>
                                <p className={this.state.plantype === 2 ? "switch-selected" : null}
                                   onClick={() => this.setState({plantype: 2})}>One Time</p>
                            </div>
                            <div className="plan-summary">
                                <p className="head mgb10">Hyperstack Plan</p>
                                <div className={this.state.plantype !== 0 ? "horizontal space-between" : "hide"}>
                                    <p id="rpysy">1000 Recipients</p>
                                    <p className="rpysy-value">600 USD</p>
                                </div>
                                <div className={this.state.plantype === 0 ? "horizontal space-between" : "hide"}>
                                    <p id="rpysm">1000 Recipients</p>
                                    <p className="rpysm-value">50 USD</p>
                                </div>
                                <p className={this.state.plantype === 0 ? "offer" : "hide"}>Save 20% Discount on Annual
                                    Plan</p>
                                <div className={this.state.plantype !== 0 ? "horizontal space-between" : "hide"}>
                                    <p className="text-bold text-green">Discount</p>
                                    <p id="discount-value" className="text-bold text-green">-120 USD</p>
                                </div>
                                <hr className={"w100"}/>
                                <div className={this.state.plantype === 0 ? "horizontal space-between" : "hide"}>
                                    <p>Total</p>
                                    <p id="payablem">50 USD</p>
                                </div>
                                <div className={this.state.plantype !== 0 ? "horizontal space-between" : "hide"}>
                                    <p>Payable</p>
                                    <p id="payabley">480 USD</p>
                                </div>

                                <div className="horizontal no-gap" id="overages">
                                    <p className="sub">Overages Per Recipient at
                                        <span id="overages-value"> 0.45 USD</span></p>
                                </div>
                                {/*<p className="sub" id="message">Renews at 21 August 2022</p>*/}
                            </div>
                            <a className={"button horizontal vcenter width-auto"} href='/'>Sign Up or Log In to
                                Continue</a>
                        </div>
                    </div>

                </div>
            </Layout>
        )
    }
}

export const Head = () => <Seo title="Pricing - Hyperstack | Smart Digital Certificates & Badges"/>

export default PlanBuilder
